import React from "react";
import classes from "../Privacy/pagebody.module.css";

const PageBody = () => {
  return (
    <div className={classes.container}>
      <div className={classes.headingContainer}>
        <p className={classes.heading}>Risk Disclosure</p>
      </div>

      <div className={classes.contentContainer}>
        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>1.</span> Trading Risks
          </p>
          <p className={classes.text}>
            Trading in CFDs and Forex is associated with a high degree of Risk
            and may not be suitable for all investors. You may sustain a loss
            that is substantially greater than your initial investment. Before
            you trade, you should carefully consider your investment objectives,
            experience level, and risk appetite.
          </p>
        </div>
        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>2.</span> Jurisdictional
            Restrictions
          </p>
          <p className={classes.text}>
            Please note that TC Bridge Limited cannot accept clients who are
            citizens or residents of the United States, North Korea, Iran,
            Ontario (Canada), Ivory Coast, or Puerto Rico. We cannot provide
            services to clients from these jurisdictions due to our internal
            risk management policies.
          </p>
        </div>
        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>3.</span>
          </p>
          <p className={classes.text}>
            Risk Trading in CFDs and Forex involves significant liquidity risk.
            Market conditions, including economic events, may lead to a sudden
            reduction or complete lack of liquidity in the market, preventing
            you from closing your positions at the best available price.
          </p>
        </div>
        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>4.</span> Technology Risk
          </p>
          <p className={classes.text}>
            Our platform relies on advanced technology to provide our services.
            We cannot guarantee that our platform will be uninterrupted,
            error-free, or free from viruses or other harmful components. We
            cannot be held liable for any loss or damage arising from your use
            of our platform or services.
          </p>
        </div>
        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>5.</span> Third-Party Risk
          </p>
          <p className={classes.text}>
            We may use third-party service providers to provide our platform or
            services. We cannot control the actions or policies of these
            providers and cannot be held liable for any loss or damage arising
            from their actions or policies.
          </p>
        </div>
        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>6.</span> Termination Risk
          </p>
          <p className={classes.text}>
            We reserve the right to terminate or suspend your account and access
            to our services at any time and for any reason, including for breach
            of our terms of use or risk management policies.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageBody;
