import React, { useEffect, useRef, useState } from "react";
import WhyCard from "./WhyCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classes from "./styles/why-bridge.module.css";
import Wallet from "../../assets/svgs/Wallet.svg";
import Graph from "../../assets/svgs/Graph.svg";
import Computer from "../../assets/svgs/Computer.svg";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

const importances = [
  {
    title: "Markets",
    message: "Forex, Crypto, Indiced, Shares, Bonds",
    fade: "left",
    icon: Wallet,
    half: true,
  },
  {
    title: "Trading Conditions",
    message:
      "Attractive Margin & Leverage, Spreads, Trading Hours, Swaps, and more",
    fade: "right",
    icon: Graph,
    half: true,
  },
  {
    title: "Dealing Model",
    message: "World Class Trading, Ethical Dealing Model, Best Execution",
    fade: "left",
    icon: Computer,
    half: false,
  },
];

const WhyBridge = () => {
  const [sliderIndex, setSliderIndex] = useState("0");
  const sliderRef = useRef();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current?.slickGoTo(parseInt(sliderIndex));
    }
  }, [sliderIndex]);
  return (
    <div className={classes.container}>
      <p className={classes.heading}>Why TC Bridge</p>

      <div className={classes.sliderContainer}>
        <Slider
          afterChange={(index) => setSliderIndex(index)}
          ref={sliderRef}
          {...settings}
        >
          {importances?.map((importance, index) => (
            <WhyCard
              key={index}
              message={importance.message}
              heading={importance.title}
              icon={importance.icon}
            />
          ))}
        </Slider>
        <div className={classes.sliderInputContainer}>
          {/* <RangeSlider
            step={1}
            // value={sliderIndex + 1}
            id={"single_slider"}
            max={3}
            // onCha
          /> */}

          <RangeSlider
            id="single_slider"
            defaultValue={[0, sliderIndex]}
            thumbsDisabled={[true, false]}
            rangeSlideDisabled={true}
            max={2}
            step={1}
            value={[0, sliderIndex]}
            onInput={(e) => {
              console.log(e);
              setSliderIndex(e[1]);
            }}
          />
        </div>
      </div>
      <div className={classes.importanceList}>
        {importances?.map((importance, index) => (
          <WhyCard
            key={index}
            message={importance.message}
            heading={importance.title}
            fade={`fade-${importance.fade}`}
            icon={importance.icon}
            half={importance.half}
          />
        ))}
      </div>
    </div>
  );
};

export default WhyBridge;
