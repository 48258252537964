import React from "react";
import classes from "../Privacy/pagebody.module.css";

const PageBody = () => {
  return (
    <div className={classes.container}>
      <div className={classes.headingContainer}>
        <p className={classes.heading}>Terms of Use</p>
      </div>

      <div className={classes.contentContainer}>
        <p className={classes.text}>
          Welcome to TC Bridge Limited. By accessing our website and using our
          services, you agree to comply with these terms.
        </p>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>1.</span> About TC Bridge
            Limited
          </p>
          <p className={classes.text}>
            TC Bridge Limited is an online trading platform that provides access
            to global financial markets. We offer a range of trading
            instruments, including Forex, Indices, Commodities, and
            Cryptocurrencies.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>2.</span>Use of our services
          </p>
          <p className={classes.text}>
            You must be at least 18 years old to use our services. You must also
            ensure that the information you provide is accurate and complete.
            You must not use our services for any illegal or unauthorized
            purpose or in any way that may damage our reputation or interfere
            with our operations.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>3.</span> Risks and limitations
          </p>
          <p className={classes.text}>
            Trading in CFDs and Forex involves significant risk, and you could
            lose more than your initial investment. Past performance is not
            indicative of future results. We do not provide investment advice or
            recommendations and cannot guarantee the accuracy or completeness of
            any information provided on our platform.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>4.</span> Intellectual Property
          </p>
          <p className={classes.text}>
            All content on our website, including text, graphics, logos, and
            images, is our property or the property of our licensors. You may
            not use, copy, or reproduce any content without our prior written
            consent.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>5.</span> Privacy and data
            protection
          </p>

          <p className={classes.text}>
            We take your privacy very seriously and comply with data protection
            legislation. Please refer to our privacy policy for more
            information.
          </p>
        </div>
        <div className={classes.card}>
          <p className={classes.cardHeading}>
            {" "}
            <span className={classes.smallText}>6.</span> Termination and
            Suspension
          </p>

          <p className={classes.text}>
            We reserve the right to suspend or terminate your account and access
            to our services at any time and for any reason, including for breach
            of these terms of use.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            {" "}
            <span className={classes.smallText}>7.</span> Liability
          </p>
          <p className={classes.text}>
            To the extent permitted by law, we exclude all liability for any
            direct or indirect loss, damage, or expense arising from your use of
            our services or any information provided on our platform.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            {" "}
            <span className={classes.smallText}>8.</span> Changes to these terms
          </p>
          <p className={classes.text}>
            We may update these terms of use from time to time to reflect
            changes in our services or legal requirements. We will notify you of
            any significant changes and obtain your consent where the law
            requires.{" "}
          </p>
        </div>

        <p className={`${classes.text} ${classes.last}`}>
          If you have any questions or concerns about these terms of use or our
          services, please get in touch with us at support@tcbridge.com.
        </p>
      </div>
    </div>
  );
};

export default PageBody;
