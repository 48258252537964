import React from "react";
import Benefits from "../components/home/Benefits";
import Hero from "../components/home/Hero";
import Platforms from "../components/home/Platforms";
import Started from "../components/home/Started";
import WhyBridge from "../components/home/WhyBridge";
// import BridgeGap from "../components/Home/BridgeGap";
// import FAQ from "../components/Home/FAQ";
// import Works from "../components/Home/Works";
import WebsiteLayout from "../components/layouts/WebsiteLayout";

const Home = () => {
  return (
    <WebsiteLayout>
      <Hero />
      <Started />
      <WhyBridge />
      <Platforms />
      <Benefits />
    </WebsiteLayout>
  );
};

export default Home;
