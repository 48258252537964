import React from "react";
import classes from "./styles/hero.module.css";
import { NavLink } from "react-router-dom";

const Hero = () => {
  return (
    <div className={classes.hero}>
      <div>
        <h3 className={classes.title}>Your Trusted FX and CFD Broker</h3>
      </div>

      <div className={classes.textContainer}>
        <div className={classes.textBar}></div>
        <h5 className={classes.textsm}>
          TC Bridge Limited is a leading online broker providing access to
          global financial markets.
        </h5>
        <h5 className={classes.textlg}>
          TC Bridge Limited is a leading online broker providing access to
          global financial markets. Our platform offers a range of trading
          instruments, advanced charting tools, competitive spreads, and
          reliable execution.
        </h5>
      </div>

      <div>
        <NavLink
          className={classes.linkItem}
          style={{ textDecoration: "none" }}
          to={"/request-demo"}
        >
          <button className={classes.salesBtn}>Contact Sales</button>
        </NavLink>
      </div>
    </div>
  );
};

export default Hero;
