import React, { useState } from "react";
import classes from "./contact-form.module.css";
import ReactFlagsSelect from "react-flags-select";
import useIsMobile from "../../hooks/useIsMobile";
import { useForm } from "react-hook-form";
import { countryListAllIsoData } from "../../constants/countrylist";
import toast from "react-hot-toast";
import { NavLink } from "react-router-dom";

const ContactForm = () => {
  const [countryCode, setCountryCode] = useState("CA");
  const { isMobile } = useIsMobile();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getCountryCode = (country_code = countryCode) => {
    const country = countryListAllIsoData?.find(
      (isoData) => isoData.code == country_code
    );
    return country?.number;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const onSubmit = async (_data) => {
    const data = {
      ..._data,
      Phone: `${getCountryCode(countryCode)}${_data.Phone}`,
    };
    const formData = new FormData();

    Object.keys(data)?.forEach((key) => {
      formData.append(`data[${key}]`, data[key]);
    });
    setIsSubmitting(true);
    const request = await fetch("https://sheetdb.io/api/v1/h5tix1awucnle", {
      method: "POST",
      body: formData,
    });

    setIsSubmitting(false);

    if (request.ok) {
      setValue("Legal First Name", "");
      setValue("Legal Last Name", "");
      setValue("Email", "");
      setValue("Phone", "");
      setValue("Headquarters", "");
      setValue("Number of employees", "");
      setValue("Company Name", "");
      setValue("Company Website", "");
      setValue("Description", "");
      setValue("Policy", "");
      window?.scrollTo(0, 0);
      toast.success("Successfully submitted your request");
    } else {
      toast.error("Sorry there was an error submitting your request");
    }
  };

  return (
    <div className={classes.container}>
      <h3 className={classes.title}>Get Started with TC Bridge</h3>
      <p className={classes.text}>
        We will contact you within 24 business hours
      </p>

      <form
        onSubmit={handleSubmit(async (data) => await onSubmit(data))}
        className={classes.form}
      >
        <div className={classes.company_group}>
          <div>
            <input
              className={classes.form_field}
              {...register("Legal First Name", { required: true })}
              placeholder="Legal first name*"
            />

            <p className={classes.form_error}>
              {errors?.["Legal First Name"] && "First name is required"}
            </p>
          </div>

          <div>
            <input
              {...register("Legal Last Name", { required: true })}
              className={classes.form_field}
              placeholder="Legal last name*"
            />
            <p className={classes.form_error}>
              {errors?.["Legal Last Name"] && "Last name is required"}
            </p>
          </div>
        </div>
        <div>
          <input
            className={classes.form_field}
            placeholder="Email address*"
            type={"email"}
            {...register("Email", { required: true })}
          />
          <p className={classes.form_error}>
            {errors?.Email && "Email is required"}
          </p>
        </div>
        <div>
          <div className={classes.phone_input}>
            <div>
              <ReactFlagsSelect
                selected={countryCode}
                onSelect={(code) => setCountryCode(code)}
                showSelectedLabel={false}
                fullWidth={true}
                selectedSize={isMobile ? 40 : 35}
                selectButtonClassName={classes.menu_flags_button}
                className={classes.menu_flags}
                rfsKey="app-lang"
              />
            </div>
            <input
              className={classes.form_field}
              placeholder="Phone number*"
              type="tel"
              {...register("Phone", { required: true })}
              onChange={(e) => {
                setValue("Phone", e.target.value.replace(/[^\d,]/g, ""));
              }}
            />
          </div>
          <p className={classes.form_error}>
            {errors?.["Phone"] && "Phone number is required"}
          </p>
        </div>
        <div className={classes.company_group}>
          <div
            className={`${classes.select_container} ${classes.company_input}`}
          >
            <select
              className={classes.form_select}
              name=""
              id=""
              required
              {...register("Headquarters", { required: true })}
            >
              <option className="placeholder" disabled selected hidden value="">
                Headquarters (Country)
              </option>
              <option className="placeholder" value="Canada">
                Canada
              </option>
              <option className="placeholder" value="UK">
                UK
              </option>
              <option className="placeholder" value="USA">
                USA
              </option>
            </select>
            <svg
              width="13"
              height="8"
              viewBox="0 0 13 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classes.select_btn}
            >
              <path
                opacity="0.5"
                d="M10.7168 2.11011L6.68885 6.0369C6.49112 6.22966 6.17457 6.22564 5.9818 6.02791L2.05501 1.99996"
                stroke="#CCE4F0"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
            <p className={classes.form_error}>
              {errors?.Headquarters && "Headqurters is required"}
            </p>
          </div>

          <div
            className={`${classes.select_container} ${classes.company_input}`}
          >
            <select
              className={classes.form_select}
              name=""
              id=""
              required
              {...register("Number of employees", { required: true })}
            >
              <option value="" disabled selected hidden>
                Number of employees
              </option>
              <option value="0 - 10">0 - 10 </option>
              <option value="100- 1000">100- 1000</option>
              <option value="1000+">1000+</option>
            </select>
            <svg
              width="13"
              height="8"
              viewBox="0 0 13 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classes.select_btn}
            >
              <path
                opacity="0.5"
                d="M10.7168 2.11011L6.68885 6.0369C6.49112 6.22966 6.17457 6.22564 5.9818 6.02791L2.05501 1.99996"
                stroke="#CCE4F0"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
            <p className={classes.form_error}>
              {errors?.["Number of employees"] &&
                "Number of employees is required"}
            </p>
          </div>
        </div>

        <div className={classes.company_group}>
          <div>
            <input
              {...register("Company Name", { required: true })}
              className={classes.form_field}
              placeholder="Company Name"
            />
            <p className={classes.form_error}>
              {errors?.["Company Name"] && "Company Name is required"}
            </p>
          </div>
          <div>
            <input
              {...register("Company Website", { required: true })}
              className={classes.form_field}
              placeholder="Company Website"
            />
            <p className={classes.form_error}>
              {errors?.["Company Website"] && "Company Website is required"}
            </p>
          </div>
        </div>
        <div>
          <textarea
            placeholder="Short description of your company"
            className={classes.form_textarea}
            name=""
            id=""
            {...register("Description", { required: true })}
          ></textarea>
          <p className={classes.form_error}>
            {errors?.Description && "Description is required"}
          </p>
        </div>

        <div>
          <div className={classes.policyContainer}>
            <input
              style={{ width: "30px", height: "30px" }}
              {...register("Policy", { required: true })}
              type={"checkbox"}
              className={`${classes.checkInput} form-check-input`}
            />
            <p className={classes.policy_text}>
              We respect your data. By submitting this form, you agree that we
              may use this information in accordance with our{" "}
              <NavLink to={"/privacy"} style={{ textDecoration: "none" }}>
                <span className={classes.policy}>Privacy Policy.</span>
              </NavLink>
            </p>
          </div>
          <p className={classes.form_error}>
            {errors?.poliicy && "Please read and agree to this policy"}
          </p>
        </div>

        <div className={classes.btnContainer}>
          <button disabled={isSubmitting} className={classes.btn}>
            {!isSubmitting ? "Contact Sales" : "Loading..."}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
