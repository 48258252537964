import React from "react";
import classes from "./styles/navbar.module.css";
import LogoSVG from "../../assets/svgs/Logo.svg";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const NavBar = () => {
  const { pathname } = useLocation();
  return (
    <section className={classes.navbar}>
      <div className={classes.logo}>
        <NavLink
          className={classes.linkItem}
          style={{ textDecoration: "none" }}
          to={"/"}
        >
          <img className={classes.logoContainer} src={LogoSVG} />
        </NavLink>
      </div>
      <div className={classes.navLink}>
        <div className={classes.actionMenu}>
          {!["/request-demo", "/contact"]?.includes(pathname) && (
            <NavLink to={"/request-demo"}>
              <button className={classes.demoBtn}>Contact Sales</button>
            </NavLink>
          )}
        </div>
      </div>
    </section>
  );
};

export default NavBar;
