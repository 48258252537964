import React from "react";
import { Link } from "react-router-dom";
import classes from "./styles/started.module.css";
import useIsMobile from "../../hooks/useIsMobile";

const Started = () => {
  const { isMobile } = useIsMobile();
  return (
    <div data-aos="fade-right" className={classes.container}>
      <p className={classes.heading}>Getting Started</p>

      <div className={classes.cardContainer}>
        <div className={classes.card}>
          <div>
            <svg
              width="43"
              height="46"
              viewBox="0 0 43 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M41.5667 31.5324C42.355 31.5324 43 30.8874 43 30.0991V4.29987C43 1.93494 41.065 0 38.7 0H7.16667C4.80167 0 2.86667 1.93494 2.86667 4.29987V10.033H1.43333C0.645 10.033 0 10.678 0 11.4663C0 12.2546 0.645 12.8996 1.43333 12.8996H2.86667V15.7662H1.43333C0.645 15.7662 0 16.4112 0 17.1995C0 17.9878 0.645 18.6328 1.43333 18.6328H2.86667V21.4993H1.43333C0.645 21.4993 0 22.1443 0 22.9326C0 23.7209 0.645 24.3659 1.43333 24.3659H2.86667V27.2325H1.43333C0.645 27.2325 0 27.8775 0 28.6658C0 29.4541 0.645 30.0991 1.43333 30.0991H2.86667V32.9656H1.43333C0.645 32.9656 0 33.6106 0 34.3989C0 35.1872 0.645 35.8322 1.43333 35.8322H2.86667V41.5654C2.86667 43.9303 4.80167 45.8652 7.16667 45.8652H38.7C41.065 45.8652 43 43.9303 43 41.5654V35.8322C43 35.0439 42.355 34.3989 41.5667 34.3989C40.7783 34.3989 40.1333 35.0439 40.1333 35.8322V41.5654C40.1333 42.3537 39.4883 42.9987 38.7 42.9987H7.16667C6.37833 42.9987 5.73333 42.3537 5.73333 41.5654V35.8322H8.6V38.6988C8.6 39.4871 9.245 40.1321 10.0333 40.1321H35.8333C36.6217 40.1321 37.2667 39.4871 37.2667 38.6988V7.16644C37.2667 6.37813 36.6217 5.73315 35.8333 5.73315H10.0333C9.245 5.73315 8.6 6.37813 8.6 7.16644V10.033H5.73333V4.29987C5.73333 3.51156 6.37833 2.86658 7.16667 2.86658H38.7C39.4883 2.86658 40.1333 3.51156 40.1333 4.29987V30.0991C40.1333 30.8874 40.7783 31.5324 41.5667 31.5324ZM5.73333 18.6328H8.6V21.4993H5.73333V18.6328ZM5.73333 24.3659H8.6V27.2325H5.73333V24.3659ZM5.73333 32.9656V30.0991H8.6V32.9656H5.73333ZM11.4667 8.59973H34.4V37.2655H11.4667V8.59973ZM8.6 12.8996V15.7662H5.73333V12.8996H8.6Z"
                fill="#E24856"
              />
              <path
                d="M27.0757 22.5744C30.5301 18.9338 27.9644 12.8997 22.9334 12.8997C17.9024 12.8997 15.3224 18.9195 18.791 22.5744C16.1394 24.0363 14.3334 26.8599 14.3334 30.0991V31.5324C14.3334 32.3207 14.9784 32.9657 15.7667 32.9657H30.1C30.8884 32.9657 31.5334 32.3207 31.5334 31.5324V30.0991C31.5334 26.8599 29.7274 24.0363 27.0757 22.5744ZM20.0667 18.6328C20.0667 17.0562 21.3567 15.7662 22.9334 15.7662C24.51 15.7662 25.8 17.0562 25.8 18.6328C25.8 20.2094 24.51 21.4994 22.9334 21.4994C21.3567 21.4994 20.0667 20.2094 20.0667 18.6328ZM17.2 30.0991C17.2 26.9316 19.7657 24.366 22.9334 24.366C26.101 24.366 28.6667 26.9316 28.6667 30.0991H17.2Z"
                fill="#E24856"
              />
            </svg>
          </div>

          <p className={classes.cardText}>
            Fill out the online registration form with your personal information
          </p>
        </div>
        <div className={classes.card}>
          <div>
            <svg
              width="44"
              height="46"
              viewBox="0 0 44 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.8941 34.4901H17.2627C16.4715 34.4901 15.8242 33.8427 15.8242 33.0514V17.2253H10.07C8.86157 17.2253 8.19984 15.8297 8.94789 14.8945L20.4563 0.507155C21.003 -0.169052 22.1538 -0.169052 22.7005 0.507155L34.2089 14.8945C34.957 15.8297 34.2953 17.2253 33.0869 17.2253H27.3326V33.0514C27.3326 33.8427 26.6853 34.4901 25.8941 34.4901ZM18.7013 31.6126H24.4555V15.7865C24.4555 14.9952 25.1029 14.3478 25.8941 14.3478H30.0947L21.5784 3.70115L13.0622 14.3478H17.2627C18.0539 14.3478 18.7013 14.9952 18.7013 15.7865V31.6126Z"
                fill="#E24856"
              />
              <path
                d="M38.841 46H12.947C12.1558 46 11.5085 45.3525 11.5085 44.5612C11.5085 43.7699 12.1558 43.1225 12.947 43.1225H38.841C39.6322 43.1225 40.2796 42.4751 40.2796 41.6838V30.1739H37.4025V38.8063C37.4025 39.5976 36.7551 40.245 35.9639 40.245H7.19278C6.40158 40.245 5.75423 39.5976 5.75423 38.8063V30.1739H2.87711V41.6838C2.87711 42.4751 3.52446 43.1225 4.31567 43.1225H7.19278C7.98399 43.1225 8.63134 43.7699 8.63134 44.5612C8.63134 45.3525 7.98399 46 7.19278 46H4.31567C1.94205 46 0 44.0577 0 41.6838V28.7351C0 27.9438 0.647351 27.2964 1.43856 27.2964H7.19278C7.98399 27.2964 8.63134 27.9438 8.63134 28.7351V37.3675H34.5254V28.7351C34.5254 27.9438 35.1727 27.2964 35.9639 27.2964H41.7182C42.5094 27.2964 43.1567 27.9438 43.1567 28.7351V41.6838C43.1567 44.0577 41.2147 46 38.841 46Z"
                fill="#E24856"
              />
            </svg>
          </div>

          <p className={classes.cardText}>
            Upload the necessary documents to verify your identity and account
          </p>
        </div>
        <div className={classes.card}>
          <div>
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8202 18.6875C15.3314 18.6875 16.539 19.2194 0.797837 11.3419C-0.265946 10.81 -0.265946 9.30064 0.797837 8.76877L15.1733 1.58127C16.1364 1.09252 17.2577 1.81125 17.2577 2.86062V17.2356C17.2577 18.0262 16.6252 18.6731 15.8202 18.6731V18.6875ZM4.66483 10.0625L14.3826 14.9213V5.20375L4.66483 10.0625Z"
                fill="#E24856"
              />
              <path
                d="M39.2377 29.7706C39.7265 30.2594 40.4884 30.3312 41.049 29.9431L45.3617 27.0681C46.1092 26.565 46.2242 25.5013 45.5773 24.8544L39.2377 18.515C38.4183 17.6956 37.3402 17.25 36.1901 17.25H35.9026C39.5108 12.4919 36.0751 5.75 30.1956 5.75C24.316 5.75 20.8803 12.4919 24.4885 17.25H17.2577V1.4375C17.2577 0.646875 16.6108 0 15.8201 0C15.0295 0 14.3826 0.646875 14.3826 1.4375V44.5625C14.3826 45.3531 15.0295 46 15.8201 46C16.6108 46 17.2577 45.3531 17.2577 44.5625V25.875H23.0078C23.0078 30.0581 23.0366 29.9 22.9503 30.1731L18.7527 44.1456C18.4796 45.0656 19.1696 46 20.1327 46H25.8829C26.4867 46 27.0186 45.6263 27.2342 45.0656L30.1956 37.1594L33.1569 45.0656C33.3725 45.6263 33.9044 46 34.5082 46H40.2584C41.2215 46 41.9115 45.0656 41.6384 44.1456L40.7759 41.2706C40.5459 40.5087 39.7552 40.0775 38.979 40.3075C38.2171 40.5375 37.7858 41.3425 38.0158 42.1044L38.3177 43.125H35.5001L31.5325 32.5594C31.0725 31.3231 29.3043 31.3231 28.8443 32.5594L24.8766 43.125H22.0591L25.696 31.0069C25.9404 30.2019 25.8829 30.1875 25.8829 24.4375C25.8829 23.6469 25.236 23 24.4454 23H17.2577V20.125H36.1901C36.5783 20.125 36.9376 20.2688 37.2108 20.5419L42.3141 25.645L40.4453 26.8956L36.9664 23.4169C36.0464 22.4969 34.5082 23.1725 34.5082 24.4375C34.5082 31.8406 34.1201 29.1094 36.2907 36.3544C36.5208 37.1163 37.3258 37.5475 38.0877 37.3175C38.8496 37.0875 39.2808 36.2825 39.0508 35.5206C37.0526 28.8506 37.3833 30.6619 37.3833 27.9019L39.2377 29.7563V29.7706ZM25.8829 12.9375C25.8829 10.5656 27.8236 8.625 30.1956 8.625C32.5675 8.625 34.5082 10.5656 34.5082 12.9375C34.5082 15.3094 32.5675 17.25 30.1956 17.25C27.8236 17.25 25.8829 15.3094 25.8829 12.9375Z"
                fill="#E24856"
              />
            </svg>
          </div>

          <p className={classes.cardText}>
            Fund your account using one of our secure payment methods and start
            trading
          </p>
        </div>
      </div>

      <div className={classes.cardLg}>
        <div className={`${classes.card} ${classes.line}`}>
          <div className={classes.iconContainer}>
            <svg
              width="40"
              height="30"
              viewBox="0 0 43 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M41.5667 31.5324C42.355 31.5324 43 30.8874 43 30.0991V4.29987C43 1.93494 41.065 0 38.7 0H7.16667C4.80167 0 2.86667 1.93494 2.86667 4.29987V10.033H1.43333C0.645 10.033 0 10.678 0 11.4663C0 12.2546 0.645 12.8996 1.43333 12.8996H2.86667V15.7662H1.43333C0.645 15.7662 0 16.4112 0 17.1995C0 17.9878 0.645 18.6328 1.43333 18.6328H2.86667V21.4993H1.43333C0.645 21.4993 0 22.1443 0 22.9326C0 23.7209 0.645 24.3659 1.43333 24.3659H2.86667V27.2325H1.43333C0.645 27.2325 0 27.8775 0 28.6658C0 29.4541 0.645 30.0991 1.43333 30.0991H2.86667V32.9656H1.43333C0.645 32.9656 0 33.6106 0 34.3989C0 35.1872 0.645 35.8322 1.43333 35.8322H2.86667V41.5654C2.86667 43.9303 4.80167 45.8652 7.16667 45.8652H38.7C41.065 45.8652 43 43.9303 43 41.5654V35.8322C43 35.0439 42.355 34.3989 41.5667 34.3989C40.7783 34.3989 40.1333 35.0439 40.1333 35.8322V41.5654C40.1333 42.3537 39.4883 42.9987 38.7 42.9987H7.16667C6.37833 42.9987 5.73333 42.3537 5.73333 41.5654V35.8322H8.6V38.6988C8.6 39.4871 9.245 40.1321 10.0333 40.1321H35.8333C36.6217 40.1321 37.2667 39.4871 37.2667 38.6988V7.16644C37.2667 6.37813 36.6217 5.73315 35.8333 5.73315H10.0333C9.245 5.73315 8.6 6.37813 8.6 7.16644V10.033H5.73333V4.29987C5.73333 3.51156 6.37833 2.86658 7.16667 2.86658H38.7C39.4883 2.86658 40.1333 3.51156 40.1333 4.29987V30.0991C40.1333 30.8874 40.7783 31.5324 41.5667 31.5324ZM5.73333 18.6328H8.6V21.4993H5.73333V18.6328ZM5.73333 24.3659H8.6V27.2325H5.73333V24.3659ZM5.73333 32.9656V30.0991H8.6V32.9656H5.73333ZM11.4667 8.59973H34.4V37.2655H11.4667V8.59973ZM8.6 12.8996V15.7662H5.73333V12.8996H8.6Z"
                fill="white"
              />
              <path
                d="M27.0756 22.5743C30.5299 18.9337 27.9643 12.8996 22.9333 12.8996C17.9023 12.8996 15.3222 18.9194 18.7909 22.5743C16.1392 24.0363 14.3333 26.8598 14.3333 30.0991V31.5324C14.3333 32.3207 14.9783 32.9656 15.7666 32.9656H30.0999C30.8883 32.9656 31.5333 32.3207 31.5333 31.5324V30.0991C31.5333 26.8598 29.7273 24.0363 27.0756 22.5743ZM20.0666 18.6328C20.0666 17.0561 21.3566 15.7662 22.9333 15.7662C24.5099 15.7662 25.7999 17.0561 25.7999 18.6328C25.7999 20.2094 24.5099 21.4993 22.9333 21.4993C21.3566 21.4993 20.0666 20.2094 20.0666 18.6328ZM17.1999 30.0991C17.1999 26.9315 19.7656 24.3659 22.9333 24.3659C26.1009 24.3659 28.6666 26.9315 28.6666 30.0991H17.1999Z"
                fill="white"
              />
            </svg>
          </div>

          <h4 className={classes.cardTitle}>Step 1</h4>

          <p className={classes.cardText}>
            Fill out the online registration form with your personal information{" "}
          </p>
        </div>

        <div className={`${classes.card} ${classes.line} `}>
          <div className={classes.iconContainer}>
            <svg
              width="44"
              height="30"
              viewBox="0 0 44 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.894 34.4901H17.2626C16.4714 34.4901 15.8241 33.8427 15.8241 33.0514V17.2253H10.0698C8.86145 17.2253 8.19971 15.8297 8.94776 14.8945L20.4562 0.507155C21.0029 -0.169052 22.1537 -0.169052 22.7004 0.507155L34.2088 14.8945C34.9569 15.8297 34.2951 17.2253 33.0867 17.2253H27.3325V33.0514C27.3325 33.8427 26.6852 34.4901 25.894 34.4901ZM18.7012 31.6126H24.4554V15.7865C24.4554 14.9952 25.1028 14.3478 25.894 14.3478H30.0945L21.5783 3.70115L13.062 14.3478H17.2626C18.0538 14.3478 18.7012 14.9952 18.7012 15.7865V31.6126Z"
                fill="white"
              />
              <path
                d="M38.841 46H12.947C12.1558 46 11.5085 45.3526 11.5085 44.5613C11.5085 43.77 12.1558 43.1225 12.947 43.1225H38.841C39.6322 43.1225 40.2796 42.4751 40.2796 41.6838V30.1739H37.4025V38.8063C37.4025 39.5976 36.7551 40.2451 35.9639 40.2451H7.19278C6.40158 40.2451 5.75423 39.5976 5.75423 38.8063V30.1739H2.87711V41.6838C2.87711 42.4751 3.52446 43.1225 4.31567 43.1225H7.19278C7.98399 43.1225 8.63134 43.77 8.63134 44.5613C8.63134 45.3526 7.98399 46 7.19278 46H4.31567C1.94205 46 0 44.0577 0 41.6838V28.7352C0 27.9439 0.647351 27.2964 1.43856 27.2964H7.19278C7.98399 27.2964 8.63134 27.9439 8.63134 28.7352V37.3676H34.5254V28.7352C34.5254 27.9439 35.1727 27.2964 35.9639 27.2964H41.7182C42.5094 27.2964 43.1567 27.9439 43.1567 28.7352V41.6838C43.1567 44.0577 41.2147 46 38.841 46Z"
                fill="white"
              />
            </svg>
          </div>

          <h4 className={classes.cardTitle}>Step 2</h4>

          <p className={`${classes.cardText} ${classes.middle}`}>
            Upload the necessary documents to verify your identity and account
          </p>
        </div>

        <div className={`${classes.card}`}>
          <div className={classes.iconContainer}>
            <svg
              width="46"
              height="30"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8202 18.6875C15.3314 18.6875 16.539 19.2194 0.797837 11.3419C-0.265946 10.81 -0.265946 9.30064 0.797837 8.76877L15.1733 1.58127C16.1364 1.09252 17.2577 1.81125 17.2577 2.86062V17.2356C17.2577 18.0262 16.6252 18.6731 15.8202 18.6731V18.6875ZM4.66483 10.0625L14.3826 14.9213V5.20375L4.66483 10.0625Z"
                fill="white"
              />
              <path
                d="M39.2377 29.7706C39.7265 30.2594 40.4884 30.3312 41.049 29.9431L45.3617 27.0681C46.1092 26.565 46.2242 25.5013 45.5773 24.8544L39.2377 18.515C38.4183 17.6956 37.3402 17.25 36.1901 17.25H35.9026C39.5108 12.4919 36.0751 5.75 30.1956 5.75C24.316 5.75 20.8803 12.4919 24.4885 17.25H17.2577V1.4375C17.2577 0.646875 16.6108 0 15.8201 0C15.0295 0 14.3826 0.646875 14.3826 1.4375V44.5625C14.3826 45.3531 15.0295 46 15.8201 46C16.6108 46 17.2577 45.3531 17.2577 44.5625V25.875H23.0078C23.0078 30.0581 23.0366 29.9 22.9503 30.1731L18.7527 44.1456C18.4796 45.0656 19.1696 46 20.1327 46H25.8829C26.4867 46 27.0186 45.6263 27.2342 45.0656L30.1956 37.1594L33.1569 45.0656C33.3725 45.6263 33.9044 46 34.5082 46H40.2584C41.2215 46 41.9115 45.0656 41.6384 44.1456L40.7759 41.2706C40.5459 40.5087 39.7552 40.0775 38.979 40.3075C38.2171 40.5375 37.7858 41.3425 38.0158 42.1044L38.3177 43.125H35.5001L31.5325 32.5594C31.0725 31.3231 29.3043 31.3231 28.8443 32.5594L24.8766 43.125H22.0591L25.696 31.0069C25.9404 30.2019 25.8829 30.1875 25.8829 24.4375C25.8829 23.6469 25.236 23 24.4454 23H17.2577V20.125H36.1901C36.5783 20.125 36.9376 20.2688 37.2108 20.5419L42.3141 25.645L40.4453 26.8956L36.9664 23.4169C36.0464 22.4969 34.5082 23.1725 34.5082 24.4375C34.5082 31.8406 34.1201 29.1094 36.2907 36.3544C36.5208 37.1163 37.3258 37.5475 38.0877 37.3175C38.8496 37.0875 39.2808 36.2825 39.0508 35.5206C37.0526 28.8506 37.3833 30.6619 37.3833 27.9019L39.2377 29.7563V29.7706ZM25.8829 12.9375C25.8829 10.5656 27.8236 8.625 30.1956 8.625C32.5675 8.625 34.5082 10.5656 34.5082 12.9375C34.5082 15.3094 32.5675 17.25 30.1956 17.25C27.8236 17.25 25.8829 15.3094 25.8829 12.9375Z"
                fill="white"
              />
            </svg>
          </div>

          <h4 className={classes.cardTitle}>Step 3</h4>

          <p className={classes.cardText}>
            Fund your account using one of our secure payment methods and start
            trading
          </p>
        </div>
      </div>

      <div className={classes.demoContainer}>
        <Link style={{ textDecoration: "none" }} to={"/request-demo"}>
          <button className={classes.demoBtn}>Join TC Bridge</button>
        </Link>
      </div>
    </div>
  );
};

export default Started;
