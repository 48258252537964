import React from "react";
import Request from "../../assets/svgs/request.svg";
import classes from "./info.module.css";

const InfoSection = () => {
  return (
    <div className={classes.container}>
      <h2 className={classes.heading}>
        Unleash Your Trading Potential with TC Bridge
      </h2>

      <p className={classes.text}>
        Discover what it's like to trade with a leading online broker providing
        access to global financial markets.
      </p>

      <div className={classes.imgContainer}>
        <img src={Request} />
      </div>
    </div>
  );
};

export default InfoSection;
