import React from "react";
import classes from "./styles/footer.module.css";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.subContainer}>
          <div className={classes.linkContainer}>
            <div className={classes.linkSubContainer}>
              <h2 className={classes.textLogo}>TC Bridge Limited</h2>

              <p className={classes.linkItem}>Plot Number 131, Boils Hills,</p>
              <p className={classes.linkItem}>Abokobi, Accra, Ga East,</p>
              <p className={classes.linkItem}>Postal Code: 123345</p>
              <p className={classes.linkItem}>Registration no : CS154620721</p>
            </div>
            <div className={`${classes.linkSubContainer} ${classes.middle}`}>
              <h2 className={classes.textLogo}>Policies</h2>
              <NavLink
                className={classes.linkItem}
                style={{ textDecoration: "none" }}
                to={"/privacy"}
              >
                <p className={classes.linkItem}>Privacy</p>
              </NavLink>

              <NavLink
                className={classes.linkItem}
                style={{ textDecoration: "none" }}
                to={"/terms-of-use"}
              >
                <p className={classes.linkItem}>Terms of Use</p>
              </NavLink>

              <NavLink
                className={classes.linkItem}
                style={{ textDecoration: "none" }}
                to={"/disclosure"}
              >
                <p className={classes.linkItem}>Risk Disclosure</p>
              </NavLink>

              <NavLink
                className={classes.linkItem}
                style={{ textDecoration: "none" }}
                to={"/anti-money-laundering"}
              >
                <p className={classes.linkItem}>Anti Money Laundering</p>
              </NavLink>
            </div>
            <div className={classes.linkSubContainer}>
              {/* <p className={classes.linkItem}>Client login</p> */}
              <NavLink
                className={classes.textLogo}
                style={{ textDecoration: "none" }}
                to={"/contact"}
              >
                <h2 className={classes.textLogo}>Contact Us</h2>
              </NavLink>
              <NavLink
                className={classes.linkItem}
                style={{ textDecoration: "none" }}
                to={"/contact"}
              >
                <p className={`${classes.linkItem} ${classes.mobile_only}`}>
                  Contact Us
                </p>
              </NavLink>
              <NavLink
                className={classes.linkItem}
                style={{ textDecoration: "none" }}
                to={"/request-demo"}
              >
                <button className={classes.demoBtn}>Talk to Sales</button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.copyRightContainer}>
        <p className={classes.copyRight}>
          Copyright © 2023, TC Bridge Limited.
        </p>
        <p className={classes.copyRight}>
          All Rights Reserved. Risk Warning: Engaging in trading of CFDs and FX
          involves significant risk and may not be suitable for everyone. If you
          are a professional client, your potential losses could greatly exceed
          your initial investment. It is important to note that you do not
          possess ownership or rights to the underlying assets. Past performance
          is not indicative of future results, and tax regulations are subject
          to changes. The information provided on this website is of a general
          nature and may not account for your individual objectives, financial
          situation, or needs. Prior to making any trading decisions, we advise
          you to evaluate whether you are part of our intended audience by
          reviewing our policies and other legal documents to fully comprehend
          the risks involved. If necessary, we encourage you to seek independent
          advice.
        </p>

        <p className={classes.copyRight}>
          TC Bridge Limited is unable to accept clients who are citizens or
          residents of the United States, North Korea, Iran, British Columbia,
          Ontario (Canada), Ivory Coast, or Puerto Rico.
        </p>
      </div>
    </div>
  );
};

export default Footer;
