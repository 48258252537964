import React, { useState } from "react";
import classes from "./contact-form.module.css";
import ReactFlagsSelect from "react-flags-select";
import useIsMobile from "../../hooks/useIsMobile";
import { useForm } from "react-hook-form";
import { countryListAllIsoData } from "../../constants/countrylist";
import toast from "react-hot-toast";

const ContactForm = () => {
  const [countryCode, setCountryCode] = useState("CA");
  const { isMobile } = useIsMobile();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getCountryCode = (country_code) => {
    const country = countryListAllIsoData?.find(
      (isoData) => isoData.code == country_code
    );
    return country?.number;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const onSubmit = async (_data) => {
    const data = {
      ..._data,
      Phone: `${getCountryCode(_data?.Phone)}${_data.Phone}`,
    };
    const formData = new FormData();

    Object.keys(data)?.forEach((key) => {
      formData.append(`data[${key}]`, data[key]);
    });
    setIsSubmitting(true);
    const request = await fetch(
      "https://sheetdb.io/api/v1/h5tix1awucnle?sheet=Support",
      {
        method: "POST",
        body: formData,
      }
    );

    setIsSubmitting(false);

    if (request.ok) {
      setValue("Name", "");

      setValue("Email Address", "");
      setValue("Subject", "");
      setValue("Message", "");

      window?.scrollTo(0, 0);
      toast.success("Successfully submitted your request");
    } else {
      toast.error("Sorry there was an error submitting your request");
    }
  };

  return (
    <div className={classes.container}>
      <h3 className={classes.title}>Contact Us</h3>
      <p className={classes.text}>
        Let us know how we can help you. You will hear back from us in 24
        business hours via email.
      </p>

      <form
        onSubmit={handleSubmit(async (data) => await onSubmit(data))}
        className={classes.form}
      >
        <div className={classes.company_group}>
          <div className={classes.group_item}>
            <input
              className={classes.form_field}
              {...register("Name", { required: true })}
              placeholder="Name*"
            />

            <p className={classes.form_error}>
              {errors?.["Name"] && "First name is required"}
            </p>
          </div>

          <div className={classes.group_item}>
            <input
              {...register("Email Address", { required: true })}
              className={classes.form_field}
              placeholder="Email Address*"
            />
            <p className={classes.form_error}>
              {errors?.["Email Address"] && "Last name is required"}
            </p>
          </div>
        </div>
        <div className={`${classes.select_container} ${classes.company_input}`}>
          <select
            className={classes.form_select}
            name=""
            id=""
            required
            {...register("Subject", { required: true })}
          >
            <option className="placeholder" disabled selected hidden value="">
              Subject
            </option>
            <option value="Pricing"> Pricing</option>
            <option value="Platform"> Platform</option>
            <option value="Documents"> Documents</option>
            <option value="Benefits"> Benefits</option>
            <option value="Other"> Other</option>
          </select>
          <svg
            width="13"
            height="8"
            viewBox="0 0 13 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classes.select_btn}
          >
            <path
              opacity="0.5"
              d="M10.7168 2.11011L6.68885 6.0369C6.49112 6.22966 6.17457 6.22564 5.9818 6.02791L2.05501 1.99996"
              stroke="#CCE4F0"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
          <p className={classes.form_error}>
            {errors?.Subject && "Headqurters is required"}
          </p>
        </div>

        <div>
          <textarea
            placeholder="How can we help you?"
            className={classes.form_textarea}
            name=""
            id=""
            {...register("Message", { required: true })}
          ></textarea>
          <p className={classes.form_error}>
            {errors?.Message && "Message is required"}
          </p>
        </div>

        <div className={classes.btnContainer}>
          <button disabled={isSubmitting} className={classes.btn}>
            {!isSubmitting ? "Send" : "Sending"}
          </button>
        </div>
      </form>
      <p className={classes.new_text}>
        You can also send your questions to{"  "}
        <a href="mailto:support@tcbridge.co">support@tcbridge.co</a>
      </p>
    </div>
  );
};

export default ContactForm;
