import React, { useEffect } from "react";
import classes from "../components/page_styles/request-demo.module.css";
import PageBody from "../components/Contact/PageBody";
import Footer from "../components/shared/Footer";
import { useLocation } from "react-router-dom";
import NavBar from "../components/shared/NavBar";

const ContactPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className={classes.page}>
      <NavBar />
      <PageBody />
      <div className={classes._footer}>
        <Footer />
      </div>
    </div>
  );
};

export default ContactPage;
