import React from "react";
import ContactForm from "./ContactForm";
import InfoSection from "./InfoSection";
import classes from "./page-body.module.css";

const PageBody = () => {
  return (
    <div className={classes.container}>
      <InfoSection />
      <hr className={classes.seperator} />
      <ContactForm />
    </div>
  );
};

export default PageBody;
