// import Dashboard from "./components/Dashboard/Dashboard";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import Home from "./pages/Home";
import ErrorPage from "./pages/ErrorPage";
import RequestDemo from "./pages/RequestDemo";
// import About from "./pages/About";
// import Service from "./pages/Service";
import { Toaster } from "react-hot-toast";
import TermOfUse from "./pages/TermsOfUse";
import Privacy from "./pages/Privacy";
import Disclosure from "./pages/Disclosure";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AntiMoney from "./pages/AntiMoney";
import ContactPage from "./pages/Contact";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <ErrorPage />,
      element: <Home />,
    },
    {
      path: "request-demo",
      element: <RequestDemo />,
    },
    // {
    //   path: "about",
    //   element: <About />,
    // },
    // {
    //   path: "services",
    //   element: <Service />,
    // },
    {
      path: "terms-of-use",
      element: <TermOfUse />,
    },
    {
      path: "privacy",
      element: <Privacy />,
    },
    {
      path: "disclosure",
      element: <Disclosure />,
    },
    {
      path: "anti-money-laundering",
      element: <AntiMoney />,
    },
    {
      path: "contact",
      element: <ContactPage />,
    },
  ]);

  useEffect(() => {
    AOS.init({
      offset: 120, // offset (in px) from the original trigger point
      delay: 200, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false,
    });
  }, []);
  return (
    <>
      <Toaster />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
