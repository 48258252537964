import React, { useRef, useState, useEffect } from "react";
import BenefitCard from "./BenefitCard";
import classes from "./styles/benefits.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

const benefits = [
  {
    icon: (
      <svg
        width="47"
        height="20"
        viewBox="0 0 47 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44.9514 26.1H43.5014V21.75C43.5014 20.9525 42.8488 20.3 42.0513 20.3C41.2538 20.3 40.6013 20.9525 40.6013 21.75V26.1H34.8011V2.9H40.6013V15.95C40.6013 16.7475 41.2538 17.4 42.0513 17.4C42.8488 17.4 43.5014 16.7475 43.5014 15.95V2.9C43.5014 1.305 42.1963 0 40.6013 0H34.8011C33.206 0 31.901 1.305 31.901 2.9V26.1H29.0009V8.7C29.0009 7.105 27.6959 5.8 26.1008 5.8H20.3006C18.7056 5.8 17.4005 7.105 17.4005 8.7V26.1H14.5005V17.4C14.5005 15.805 13.1954 14.5 11.6004 14.5H5.80018C4.20513 14.5 2.90009 15.805 2.90009 17.4V26.1H1.45005C0.65252 26.1 0 26.7525 0 27.55C0 28.3475 0.65252 29 1.45005 29H44.9514C45.7489 29 46.4014 28.3475 46.4014 27.55C46.4014 26.7525 45.7489 26.1 44.9514 26.1ZM5.80018 26.1V17.4H11.6004V26.1H5.80018ZM20.3006 26.1V8.7H26.1008V26.1H20.3006Z"
          fill="#141414"
        />
      </svg>
    ),
    title: "Spread & Order Book",
    text: "Our Spreads and Order Book give you an edge in the markets.",
  },
  {
    icon: (
      <svg
        width="39"
        height="25"
        viewBox="0 0 39 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35.3437 9.75116H15.1855C14.5152 9.75116 13.9668 9.20265 13.9668 8.53225C13.9668 7.86186 14.5152 7.31335 15.1855 7.31335H35.3437C36.014 7.31335 36.5624 6.76485 36.5624 6.09445C36.5624 5.42405 36.014 4.87555 35.3437 4.87555H15.1855C14.5152 4.87555 13.9668 4.32704 13.9668 3.65665C13.9668 2.98625 14.5152 2.43774 15.1855 2.43774H35.3437C37.3546 2.43774 38.9999 4.08326 38.9999 6.09445C38.9999 8.10564 37.3546 9.75116 35.3437 9.75116Z"
          fill="#141414"
        />
        <path
          d="M6.75188 9.75116H3.65625C1.64531 9.75116 0 8.10564 0 6.09445C0 4.08326 1.64531 2.43774 3.65625 2.43774H6.75188C7.42219 2.43774 7.97063 2.98625 7.97063 3.65665C7.97063 4.32704 7.42219 4.87555 6.75188 4.87555H3.65625C2.98594 4.87555 2.4375 5.42405 2.4375 6.09445C2.4375 6.76485 2.98594 7.31335 3.65625 7.31335H6.75188C7.42219 7.31335 7.97063 7.86186 7.97063 8.53225C7.97063 9.20265 7.42219 9.75116 6.75188 9.75116Z"
          fill="#141414"
        />
        <path
          d="M10.9688 12.189C7.605 12.189 4.875 9.45868 4.875 6.09451C4.875 2.73034 7.605 0 10.9688 0C14.3325 0 17.0625 2.73034 17.0625 6.09451C17.0625 9.45868 14.3325 12.189 10.9688 12.189ZM10.9688 2.4378C8.95781 2.4378 7.3125 4.08332 7.3125 6.09451C7.3125 8.1057 8.95781 9.75121 10.9688 9.75121C12.9797 9.75121 14.625 8.1057 14.625 6.09451C14.625 4.08332 12.9797 2.4378 10.9688 2.4378Z"
          fill="#141414"
        />
        <path
          d="M35.3437 36.5671H22.498C21.8277 36.5671 21.2793 36.0186 21.2793 35.3482C21.2793 34.6778 21.8277 34.1293 22.498 34.1293H35.3437C36.014 34.1293 36.5624 33.5808 36.5624 32.9104C36.5624 32.24 36.014 31.6915 35.3437 31.6915H22.498C21.8277 31.6915 21.2793 31.143 21.2793 30.4726C21.2793 29.8022 21.8277 29.2537 22.498 29.2537H35.3437C37.3546 29.2537 38.9999 30.8992 38.9999 32.9104C38.9999 34.9216 37.3546 36.5671 35.3437 36.5671Z"
          fill="#141414"
        />
        <path
          d="M14.0644 36.5671H3.65625C1.64531 36.5671 0 34.9216 0 32.9104C0 30.8992 1.64531 29.2537 3.65625 29.2537H14.0644C14.7347 29.2537 15.2831 29.8022 15.2831 30.4726C15.2831 31.143 14.7347 31.6915 14.0644 31.6915H3.65625C2.98594 31.6915 2.4375 32.24 2.4375 32.9104C2.4375 33.5808 2.98594 34.1293 3.65625 34.1293H14.0644C14.7347 34.1293 15.2831 34.6778 15.2831 35.3482C15.2831 36.0186 14.7347 36.5671 14.0644 36.5671Z"
          fill="#141414"
        />
        <path
          d="M18.2812 39.0048C14.9175 39.0048 12.1875 36.2745 12.1875 32.9103C12.1875 29.5461 14.9175 26.8158 18.2812 26.8158C21.645 26.8158 24.375 29.5461 24.375 32.9103C24.375 36.2745 21.645 39.0048 18.2812 39.0048ZM18.2812 29.2536C16.2703 29.2536 14.625 30.8991 14.625 32.9103C14.625 34.9215 16.2703 36.567 18.2812 36.567C20.2922 36.567 21.9375 34.9215 21.9375 32.9103C21.9375 30.8991 20.2922 29.2536 18.2812 29.2536Z"
          fill="#141414"
        />
        <path
          d="M35.3437 23.1591H32.248C31.5777 23.1591 31.0293 22.6106 31.0293 21.9402C31.0293 21.2698 31.5777 20.7213 32.248 20.7213H35.3437C36.014 20.7213 36.5624 20.1728 36.5624 19.5024C36.5624 18.832 36.014 18.2835 35.3437 18.2835H32.248C31.5777 18.2835 31.0293 17.735 31.0293 17.0646C31.0293 16.3942 31.5777 15.8457 32.248 15.8457H35.3437C37.3546 15.8457 38.9999 17.4912 38.9999 19.5024C38.9999 21.5136 37.3546 23.1591 35.3437 23.1591Z"
          fill="#141414"
        />
        <path
          d="M18.2812 23.1591H3.65625C1.64531 23.1591 0 21.5136 0 19.5024C0 17.4912 1.64531 15.8457 3.65625 15.8457H23.8144C24.4847 15.8457 25.0331 16.3942 25.0331 17.0646C25.0331 17.735 24.4847 18.2835 23.8144 18.2835H3.65625C2.98594 18.2835 2.4375 18.832 2.4375 19.5024C2.4375 20.1728 2.98594 20.7213 3.65625 20.7213H18.2812C18.9516 20.7213 19.5 21.2698 19.5 21.9402C19.5 22.6106 18.9516 23.1591 18.2812 23.1591Z"
          fill="#141414"
        />
        <path
          d="M28.0312 25.597C24.6675 25.597 21.9375 22.8666 21.9375 19.5025C21.9375 16.1383 24.6675 13.408 28.0312 13.408C31.395 13.408 34.125 16.1383 34.125 19.5025C34.125 22.8666 31.395 25.597 28.0312 25.597ZM28.0312 15.8458C26.0203 15.8458 24.375 17.4913 24.375 19.5025C24.375 21.5137 26.0203 23.1592 28.0312 23.1592C30.0422 23.1592 31.6875 21.5137 31.6875 19.5025C31.6875 17.4913 30.0422 15.8458 28.0312 15.8458Z"
          fill="#141414"
        />
      </svg>
    ),
    title: "Trades Slippage ",
    text: "Our Slippage Protection ensures your orders are executed at the best available price.",
  },
  {
    icon: (
      <svg
        width="45"
        height="25"
        viewBox="0 0 45 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.0713 12.5357C24.347 12.5357 23.6784 11.9368 23.6784 11.1428C23.6784 10.3489 24.3052 9.74998 25.0713 9.74998H27.857C28.6231 9.74998 29.2499 9.1232 29.2499 8.35713C29.2499 7.59105 28.6231 6.96427 27.857 6.96427H26.4641C26.4641 6.1982 25.8374 5.57141 25.0713 5.57141C24.3052 5.57141 23.6784 6.1982 23.6784 6.96427V7.21498C20.6838 8.28748 20.0152 12.0343 22.1045 14.0678C22.8845 14.8618 23.9431 15.3075 25.0713 15.3075C25.4474 15.3075 25.7816 15.4468 26.0602 15.7393C26.8959 16.5471 26.3666 18.0236 25.1131 18.1071C24.347 18.1489 23.762 18.8036 23.8038 19.5836C23.957 22.1464 29.2499 20.7257 29.2499 16.7143C29.2499 14.3743 27.3416 12.5357 25.0713 12.5357Z"
          fill="#141414"
        />
        <path
          d="M12.9676 18.5946C12.3548 18.5946 11.7837 18.1768 11.6166 17.5639C9.23479 8.59393 16.018 0 25.0716 0C34.1251 0 40.8666 8.59393 38.5544 17.4664C38.3594 18.2046 37.6073 18.6643 36.8551 18.4693C36.1169 18.2743 35.6712 17.5082 35.8523 16.77C37.7048 9.6525 32.3144 2.78571 25.0716 2.78571C17.8287 2.78571 12.3966 9.69429 14.3187 16.8396C14.5555 17.7311 13.8869 18.5946 12.9676 18.5946Z"
          fill="#141414"
        />
        <path
          d="M43.9446 16.9511L41.6604 15.4329C40.0029 14.3325 37.7882 14.5554 36.3814 15.9482L30.5732 21.7564C30.3086 19.695 28.5675 18.1072 26.4504 18.1072C25.1829 18.1072 24.2218 18.1907 23.01 17.3968C18.8454 14.625 13.2739 14.4022 8.34321 18.1072V15.3214C8.34321 14.5554 7.71643 13.9286 6.95036 13.9286H1.39286C0.626786 13.9286 0 14.5554 0 15.3214V37.6072C0 38.3732 0.626786 39 1.39286 39H6.96429C7.73036 39 8.35714 38.3732 8.35714 37.6072V21.5893L10.4464 20.0154C13.6082 17.6475 18.1907 17.5222 21.4779 19.7089C23.3582 20.9625 24.9461 20.8929 26.4504 20.8929C27.2164 20.8929 27.8432 21.5197 27.8432 22.2857V23.6786H18.0932C17.3271 23.6786 16.7004 24.3054 16.7004 25.0714C16.7004 25.8375 17.3271 26.4643 18.0932 26.4643H29.2361C29.5982 26.4643 29.9604 26.3111 30.225 26.0604L38.3593 17.9261C38.8329 17.4664 39.5711 17.3829 40.1143 17.7589L41.1729 18.4554L30.3504 32.8714C30.0857 33.2197 29.6679 33.4286 29.2361 33.4286H12.5218C11.7557 33.4286 11.1289 34.0554 11.1289 34.8214C11.1289 35.5875 11.7557 36.2143 12.5218 36.2143H29.2361C30.5454 36.2143 31.7989 35.5875 32.5789 34.5429L44.2789 18.9429C44.7664 18.3022 44.5993 17.3968 43.9307 16.9511H43.9446ZM5.57143 36.2143H2.78571V16.7143H5.57143V36.2143Z"
          fill="#141414"
        />
      </svg>
    ),
    title: "Liquidity Providers",
    text: "Our Basket of Liquidity Providers ensures fast execution and competitive pricing for your trades.",
  },
];

const Benefits = () => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const sliderRef = useRef();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current?.slickGoTo(parseInt(sliderIndex));
    }
  }, [sliderIndex]);
  return (
    <div data-aos="fade-left" className={classes.container}>
      <p className={classes.heading}>More Benefits</p>

      <div className={classes.sliderContainer}>
        <Slider
          afterChange={(index) => setSliderIndex(index)}
          ref={sliderRef}
          {...settings}
        >
          {benefits?.map((benefit, index) => (
            <BenefitCard
              key={index}
              message={benefit.text}
              heading={benefit.title}
            />
          ))}
        </Slider>
        <div className={classes.sliderInputContainer}>
          {/* <RangeSlider
            step={1}
            // value={sliderIndex + 1}
            id={"single_slider"}
            max={3}
            // onCha
          /> */}

          <RangeSlider
            id="single_slider"
            defaultValue={[0, sliderIndex]}
            thumbsDisabled={[true, false]}
            rangeSlideDisabled={true}
            max={2}
            step={1}
            value={[0, sliderIndex]}
            onInput={(e) => {
              console.log(e);
              setSliderIndex(e[1]);
            }}
          />
        </div>
      </div>
      <div className={classes.importanceList}>
        {benefits?.map((benefit, index) => (
          <BenefitCard
            key={index}
            message={benefit.text}
            heading={benefit.title}
            icon={benefit.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default Benefits;
