import React from "react";
import Request from "../../assets/svgs/contact.svg";
import classes from "./info.module.css";

const InfoSection = () => {
  return (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <img src={Request} />
      </div>

      <p className={classes.text}>
        You can also send your questions to{"  "}
        <a href="mailto:support@tcbridge.co">support@tcbridge.co</a>
      </p>
    </div>
  );
};

export default InfoSection;
