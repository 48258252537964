import React from "react";

const ErrorPage = () => {
  return (
    <div>
      <p>Not Found</p>
    </div>
  );
};

export default ErrorPage;
