import React from "react";
import PageBody from "../components/Disclosure/PageBody";
import WebsiteLayout from "../components/layouts/WebsiteLayout";

const Disclosure = () => {
  return (
    <WebsiteLayout>
      <PageBody />
    </WebsiteLayout>
  );
};

export default Disclosure;
