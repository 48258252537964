import React from "react";
import classes from "./pagebody.module.css";
const PageBody = () => {
  return (
    <div className={classes.container}>
      <div className={classes.headingContainer}>
        <p className={classes.heading}>Anti Money Laundering</p>
      </div>

      <div className={classes.contentContainer}>
        <p className={classes.text}>
          At TC Bridge Limited, we are committed to preventing our services from
          being used to launder money or finance terrorism. As part of this
          commitment, we have implemented an AML policy in accordance with our
          legal and regulatory obligations.
        </p>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>1.</span> Customer Due Diligence
          </p>
          <p className={classes.text}>
            We conduct customer due diligence (CDD) checks on all our clients
            and require the submission of identification documents and other
            relevant information to verify their identity. We may also conduct
            enhanced due diligence (EDD) checks on clients who are considered
            high-risk, such as politically exposed persons (PEPs).
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>2.</span>Monitoring and
            Reporting
          </p>
          <p className={classes.text}>
            We monitor our clients' transactions and activities for suspicious
            patterns or behaviors that may indicate money laundering or
            terrorism financing. We report any suspicious transactions or
            activities to the relevant authorities as the law requires.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>3.</span> Staff Training and
            Awareness
          </p>
          <p className={classes.text}>
            We provide regular training and awareness programs to ensure they
            are familiar with our AML policy and their obligations under AML
            laws and regulations.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>4.</span> Record Keeping
          </p>
          <p className={classes.text}>
            We maintain records of our clients' identification documents,
            transactions, and activities for at least five years, per our legal
            and regulatory obligations.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>5.</span> Risk Assessment
          </p>

          <p className={classes.text}>
            We conduct periodic risk assessments of our AML policy and
            procedures to ensure they remain effective in preventing money
            laundering and terrorism financing.
          </p>
        </div>
        <div className={classes.card}>
          <p className={classes.cardHeading}>
            {" "}
            <span className={classes.smallText}>6.</span> Cooperation with
            Authorities
          </p>

          <p className={classes.text}>
            We cooperate with the relevant authorities and law enforcement
            agencies in their efforts to prevent and detect money laundering and
            terrorism financing.
          </p>
        </div>

        <p className={`${classes.text} ${classes.last}`}>
          We reserve the right to terminate or suspend the accounts of any
          clients who do not comply with our AML policy or engage in suspicious
          activities.
        </p>

        <p className={`${classes.text} ${classes.last}`}>
          If you have any questions or concerns about our AML policy, please get
          in touch with us at support@tcbridge.com.
        </p>
      </div>
    </div>
  );
};

export default PageBody;
