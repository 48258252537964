import React from "react";
import classes from "./styles/why-card.module.css";

const WhyCard = ({ heading, message, fade, icon, half }) => {
  return (
    <div
      data-aos={fade}
      className={`${classes.container} ${half && classes.half}`}
    >
      <div className={classes.icon}>
        <img src={icon} />
      </div>
      <div className={classes.info}>
        <h4 className={classes.heading}>{heading}</h4>
        <p className={classes.message}>{message}</p>
      </div>
    </div>
  );
};

export default WhyCard;
