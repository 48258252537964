import React from "react";
import classes from "./styles/benefit-card.module.css";

const AdvantageCard = ({ heading, message, icon }) => {
  return (
    <div className={classes.container}>
      <div className={classes.icon}>{icon}</div>
      <h4 className={classes.heading}>{heading}</h4>
      <p className={classes.message}>{message}</p>
    </div>
  );
};

export default AdvantageCard;
