import React from "react";
import classes from "./pagebody.module.css";

const PageBody = () => {
  return (
    <div className={classes.container}>
      <div className={classes.headingContainer}>
        <p className={classes.heading}>Privacy Policy</p>
      </div>

      <div className={classes.contentContainer}>
        <p className={classes.text}>
          At TC Bridge Limited, we take your privacy very seriously. This
          privacy policy explains how we collect, use, and protect your personal
          information per our obligations under data protection legislation.
        </p>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>1.</span> What information do we
            collect?
          </p>
          <p className={classes.text}>
            We collect the information you provide when you open an account or
            use our services. This information may include your name, address,
            date of birth, email address, telephone number, identification
            documents, and financial information. We may also collect
            information about your use of our services, such as your trading
            activity, IP address, and device information.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>2.</span>How do we use your
            information?
          </p>
          <p className={classes.text}>
            We use your personal information to provide services, process your
            transactions, and comply with our legal and regulatory obligations.
            We may also use your information to communicate about our products
            and services and improve our platform and services.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>3.</span> How do we protect your
            information?
          </p>
          <p className={classes.text}>
            We take appropriate technical and organizational measures to protect
            your personal information against unauthorized or unlawful
            processing and accidental loss, destruction, or damage. We also use
            third-party service providers to help us secure and store your data.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>4.</span> Who do we share your
            information with?
          </p>
          <p className={classes.text}>
            We may share your personal information with our service providers,
            payment processors, and regulatory authorities. We may also share
            your information with our group companies, but we will always
            protect your information.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>5.</span> How can you access and
            control your information?
          </p>

          <p className={classes.text}>
            You have the right to access, rectify, and erase your personal
            information and to restrict or object to its processing. You can
            also request a copy of your personal information or ask us to
            transfer it to another provider. To exercise your rights or for any
            other queries, please get in touch with us using the contact details
            provided below.
          </p>
        </div>
        <div className={classes.card}>
          <p className={classes.cardHeading}>
            {" "}
            <span className={classes.smallText}>6.</span> Changes to this policy
          </p>

          <p className={classes.text}>
            We may update this privacy policy from time to time to reflect
            changes in our practices and legal obligations. We will notify you
            of any significant changes and obtain your consent where the law
            requires.
          </p>
        </div>

        <p className={`${classes.text} ${classes.last}`}>
          If you have any questions or concerns about this policy or our data
          protection practices, please get in touch with us at
          privacy@tcbridge.com.
        </p>
      </div>
    </div>
  );
};

export default PageBody;
